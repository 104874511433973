import { Button } from '@octano/global-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import { useAcademicInformation } from '../../../components/careerSelector/useAcademicInformation';
import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';
import { useUserState } from '../../../hooks/useUserState';

const prefix = 'courseRegistration';

const StudyPlanStudent = () => {
  const { t } = useTranslation();
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const history = useHistory();

  const { userData } = useUserState();
  const { careers, selectedPeriod } = useAcademicInformation();

  const defaultCareer = useMemo(() => {
    return careers.find(
      (c) => String(c.studyPlanEnrollmentId) === studyPlanEnrollmentId,
    );
  }, [careers, studyPlanEnrollmentId]);

  const {
    student,
    setCampusCareer,
    setStudentInfo,
    setSelectedCareer,
    selectedCareer,
  } = useCourseSelectionState();

  const getStudentInfo = useCallback(async () => {
    if (!defaultCareer) {
      return;
    }

    setStudentInfo({
      name: userData!.fullname,
      career: [defaultCareer],
    });

    setCampusCareer({
      id: defaultCareer.campus.id,
      name: defaultCareer.campus.name,
    });

    setSelectedCareer({
      studyPlanEnrollmentId: defaultCareer.studyPlanEnrollmentId,
      career: { id: defaultCareer.id, name: defaultCareer.name },
      mention: defaultCareer.mentions[0]
        ? {
            id: defaultCareer.mentions[0].id,
            name: defaultCareer.mentions[0].name,
          }
        : null,
    });
  }, [
    setStudentInfo,
    userData,
    defaultCareer,
    setCampusCareer,
    setSelectedCareer,
  ]);

  useEffect(() => {
    getStudentInfo();
  }, [getStudentInfo]);

  const goToDetailProgression = useCallback(() => {
    if (selectedCareer) {
      history.push(
        `/enrollment/${studyPlanEnrollmentId}/progression/${
          selectedCareer.career.id
        }/${selectedCareer.mention?.id || null}`,
      );
    }
  }, [selectedCareer, history, studyPlanEnrollmentId]);

  return (
    <Card className="bg-light px-5 py-4 h-100">
      <Row className="h-100">
        <Col xs={12} className="flex-grow-1">
          <p className="text-primary text-uppercase fs-25 text-bold fw-600">
            {student?.name}
          </p>
        </Col>
        <Col className="align-self-end pb-3">
          <p className="text-primary text-uppercase fs-18 mb-0">
            {/* TODO: El alumno puede estar asociado a más de una carrera, por el momento
            solo se está desplegando la información de la primera carrera y la primera mención
            si es que tuviera más de una */}
            {/* Carrera */}
            {student?.career?.[0]?.name}
            <br />
            {/* Mención */}
            {student?.career?.[0].mentions?.[0]?.name && (
              <>
                {student?.career?.[0].mentions?.[0]?.name}
                <br />
              </>
            )}
            {/* Sede */}
            {student?.career?.[0]?.campus.name}
            <br />
            {/* Jornada */}
            {student?.career?.[0]?.schedule.name}
            {/* Período */}
            {selectedPeriod?.name}
          </p>
        </Col>
        <Col className="px-1 px-xl-4 align-self-end pb-3">
          <div style={{ maxWidth: '300px' }}>
            <Button
              text={t(`${prefix}.btnCurriculum`)}
              onClick={goToDetailProgression}
              outlined
              fullwidth
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default StudyPlanStudent;
